<template>
  <div class="configuration-home">
    <h3 class="question">All Drawers</h3>
    <table>
      <thead>
        <th>Size</th>
        <th>Status</th>
        <th>Slave ID</th>
        <th>Solenoid ID</th>
        <th>Awaiting Operation</th>
        <th>Door Status</th>
        <th>Actions</th>
      </thead>
      <tbody>
        <tr v-for="drawer in drawers" :key="drawer.id">
          <td>
            <select
              name="size"
              v-model="drawer.size_code"
              @change="
                setDrawerData({ ...drawer, size_code: $event.target.value })
              "
            >
              <option
                v-for="(value, key) in lockerConfig.drawerSizeText"
                :key="key"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
          </td>
          <td>
            <select
              name="status"
              v-model="drawer.status"
              @change="
                setDrawerData({ ...drawer, status: $event.target.value })
              "
            >
              <option
                v-for="(value, key) in lockerConfig.drawerStatusText"
                :key="key"
                :value="key"
              >
                {{ value }}
              </option>
            </select>
          </td>
          <td>
            <select
              name="slave_id"
              v-model="drawer.slave_id"
              @change="
                updateSolenoid(
                  drawer.id,
                  $event.target.value,
                  drawer.solenoid_id,
                  { ...drawer, slave_id: $event.target.value }
                )
              "
            >
              <option v-for="value in 10" :key="value" :value="value">
                {{ value }}
              </option>
            </select>
          </td>
          <td>
            <select
              name="solenoid_id"
              v-model="drawer.solenoid_id"
              @change="
                updateSolenoid(
                  drawer.id,
                  drawer.slave_id,
                  $event.target.value,
                  { ...drawer, solenoid_id: $event.target.value }
                )
              "
            >
              <option v-for="value in 16" :key="value" :value="value">
                {{ value }}
              </option>
            </select>
          </td>
          <td>{{ drawer.awaiting_operation == 1 ? "YES" : "NO" }}</td>
          <td>{{ doorStatusText(drawer.slave_id, drawer.solenoid_id) }}</td>
          <td>
            <a href="#" @click="deleteDrawer(drawer.id, $event)">Delete</a
            ><br />
            <a
              href="#"
              @click="openDrawer(drawer.slave_id, drawer.solenoid_id, $event)"
              >Open</a
            >
            <!--&nbsp;
            <a href="#" @click="cleanupDrawer(drawer.id, $event)">Cleanup</a>-->
          </td>
        </tr>
      </tbody>
    </table>
    <!--
    <button class="cleanup-button" @click="handleBulkCleanup()">
      Cleanup All Drawers!
    </button>
    -->
    <button class="cleanup-button" @click="handleBulkOpen()">
      Open All Drawers!
    </button>
    <button class="cleanup-button" @click="createDrawer()">
      Create A Drawer!
    </button>
    <button class="cleanup-button" @click="fillDrawerStates()">
      Refresh States
    </button>
    <button class="cleanup-button" @click="fillFields()">
      Refresh The Data
    </button>

    <div class="version-box">
      Version Date: {{ lockerConfig.versionNumber }}
    </div>

    <div class="modal-backdrop" v-if="modalShow">
      <div class="modal">
        {{ modalText }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.version-box {
  padding: 10px;
  background-color: #eee;
  color: #222;
}

.cleanup-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 14px;
  color: #fff;
  background-color: magenta;
  border-radius: 5px;
  margin: 10px;
}

th,
td {
  padding: 10px;
  border: 1px solid #ebebeb;
  border-collapse: collapse;
}

thead {
  background-color: green;
  color: #fff;
}

.form-error {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: rgba(red, 0.5);
  color: #222;
  margin-bottom: 30px;
}

.input-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

input {
  width: 90%;
  padding: 20px;
  border: 3px solid #ddd;
  border-radius: 0;
  font-size: 36px;
  flex-basis: 90%;
  height: 100px;
}

input:focus {
  outline: none;
  border: 3px dashed blue;
  border-radius: 10px 0 0 10px;
}

button {
  background-color: #ddd;
  border: 3px solid #ddd;
  color: #222;
  width: 100%;
  height: 100px;
  flex-basis: 15%;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

form {
  margin-bottom: 30px;
}

.configuration-home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3.question {
    font-size: 42px;
    padding: 30px;
    font-family: "Anton";
    background-color: #ebebeb;
    color: #222;
    margin-bottom: 30px;
  }

  .action-boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
    width: 100%;

    li {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        color: #fff;
        background-color: #ddd;
        margin: 10px 50px;
        width: 250px;

        .img img {
          display: block;
          width: 128px;
          padding: 10px;
          margin-top: 20px;
        }

        .title {
          display: block;
          background-color: #00838f;
          padding: 10px;
          color: #fff;
          width: 100%;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
}

.modal-backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

.modal {
  background-color: #333;
  color: #fff;
  border: 1px solid #222;
  padding: 20px;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  font-size: 32px;
}
</style>

<script>
import SimpleKeyboard from "@/components/Keyboard";
import lockerConfig from "@/lockerConfig";
import Home from "@/components/Configuration/Home.vue";
import service from "@/api/service";

export default {
  name: "ConfigurationHome",
  data() {
    return {
      drawers: [],
      lockerConfig: lockerConfig,
      modalShow: false,
      modalText: "",
      drawerStates: [],
    };
  },
  async beforeMount() {
    await this.fillFields();
  },

  methods: {
    doorStatusText(slaveId, solenoidId) {
      if (
        !this.drawerStates[slaveId] ||
        this.drawerStates[slaveId][solenoidId] == null ||
        this.drawerStates[slaveId][solenoidId] == undefined
      )
        return "N/A";
      return this.drawerStates[slaveId][solenoidId] ? "OPEN" : "CLOSED";
    },
    async fillDrawerStates() {
      for (let drawer of this.drawers) {
        const slaveId = drawer.slave_id;
        const solenoidId = drawer.solenoid_id;
        if (!this.drawerStates[slaveId]) this.drawerStates[slaveId] = [];

        try {
          const drawerState = await service.getSolenoidStatus(
            slaveId,
            solenoidId
          );
          this.drawerStates[slaveId][solenoidId] = drawerState;
        } catch (error) {
          console.log(error);
          this.drawerStates[slaveId][solenoidId] = null;
        }
      }
    },

    async createDrawer() {
      this.$swal
        .fire({
          title: "New Drawer",
          html: `
          
          <select id="size" class="swal2-input" placeholder="Size">
            <option value="s">Small</option>
            <option value="m">Medium</option>
            <option value="l">Large</option>
            <option value="xl">Extra Large</option>
          </select><br>

          <input type="number" min="1" max="10" id="slave_id" class="swal2-input" style="width: 200px;" placeholder="Slave ID"><br>
          <input type="number" min="1" max="16" id="solenoid_id" class="swal2-input" style="width: 200px;" placeholder="Solenoid ID">
          
          `,
          confirmButtonText: "Create",
          focusConfirm: false,
          preConfirm: () => {
            const size = this.$swal.getPopup().querySelector("#size").value;
            const slaveId = this.$swal
              .getPopup()
              .querySelector("#slave_id").value;
            const solenoidId = this.$swal
              .getPopup()
              .querySelector("#solenoid_id").value;
            if (!size || !slaveId || !solenoidId) {
              Swal.showValidationMessage(`Please fill all the fields!`);
            }
            return { size, slaveId, solenoidId };
          },
        })
        .then(async (result) => {
          if (result.value) {
            const { size, slaveId, solenoidId } = result.value;
            const ret = await service.createDrawer(size, slaveId, solenoidId);
            if (ret) {
              await this.$swal.fire("Drawer created successfully!");

              this.fillFields();
            } else {
              this.$swal.fire("Error creating drawer!");
            }
          }
        });
    },

    async updateSolenoid(drawerId, slaveId, solenoidId, newDrawerData) {
      let checkSol = await this.checkSameSolenoid(
        drawerId,
        slaveId,
        solenoidId
      );
      if (!checkSol) {
        return false;
      }

      await this.setDrawerData(newDrawerData);
    },
    async setDrawerData(drawerData) {
      console.log(drawerData);

      await service.updateDrawer(drawerData);
      await this.fillFields();

      return true;
    },
    async checkSameSolenoid(drawerId, slaveId, solenoidId) {
      let matchCount = 0;
      for (let drawer of this.drawers) {
        if (drawerId == drawer.id) continue;
        if (drawer.slave_id == slaveId && drawer.solenoid_id == solenoidId) {
          matchCount++;
        }
      }

      console.log("Match count", matchCount);

      if (matchCount > 0) {
        let result = await this.$swal.fire({
          title: "Are you sure?",
          text: "This solenoid is already being used by another drawer!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, use it!",
        });

        if (result.isConfirmed) {
          return true;
        } else {
          await this.fillFields();
          return false;
        }
      }

      return true;
    },
    async fillFields() {
      try {
        const drawers = await service.allDrawers();
        this.drawers = drawers;
        console.dir(drawers);
      } catch (error) {
        console.log(error);
      }
    },
    async cleanupDrawer(uniqueId, e) {
      e.preventDefault();
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, clean it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.modalText = "Processing...";
              this.modalShow = true;

              await service.cleanUpDrawer(uniqueId);

              this.modalText = "Done!";
              this.fillFields();
              setTimeout(() => (this.modalShow = false), 2000);
            } catch (error) {
              console.log(error);

              this.modalText = "An error occured!";
              this.modalShow = true;

              setTimeout(() => (this.modalShow = false), 2000);
            }
          }
        });
    },
    async deleteDrawer(uniqueId, e) {
      e.preventDefault();
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.modalText = "Processing...";
              this.modalShow = true;

              let ret = await service.deleteDrawer(uniqueId);
              if (!ret) throw "Delete drawer error.";

              this.modalText = "Done!";
              this.fillFields();
              setTimeout(() => (this.modalShow = false), 2000);
            } catch (error) {
              console.log(error);

              this.modalText = "An error occured!";
              this.modalShow = true;

              setTimeout(() => (this.modalShow = false), 2000);
            }
          }
        });

      return;
    },

    async openDrawer(slaveId, solenoidId, e) {
      e.preventDefault();
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "This operation will open a drawer!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, open it!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.modalText = "Processing...";
              this.modalShow = true;
              await new Promise((res, rej) => {
                setTimeout(res, 500);
              });

              await service.openDrawer(slaveId, solenoidId, 3);
              this.modalText = `Opened drawer #${solenoidId}...`;

              await new Promise((res, rej) => {
                setTimeout(res, 500);
              });

              this.modalText = "Done!";
              setTimeout(() => (this.modalShow = false), 2000);
            } catch (error) {
              console.log(error);

              this.modalText = "An error occured!";
              this.modalShow = true;

              setTimeout(() => (this.modalShow = false), 2000);
            }
          }
        });

      return;
    },

    async handleBulkOpen() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, open them up!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.modalText = "Processing...";
              this.modalShow = true;
              await new Promise((res, rej) => {
                setTimeout(res, 500);
              });

              for (let drawer of this.drawers) {
                await service.openDrawer(
                  drawer.slave_id,
                  drawer.solenoid_id,
                  3
                );
                this.modalText = `Opened drawer #${drawer.solenoid_id}...`;

                await new Promise((res, rej) => {
                  setTimeout(res, 500);
                });
              }

              this.modalText = "Done!";
              setTimeout(() => (this.modalShow = false), 2000);
            } catch (error) {
              console.log(error);

              this.modalText = "An error occured!";
              this.modalShow = true;

              setTimeout(() => (this.modalShow = false), 2000);
            }
          }
        });
    },
    async handleBulkCleanup() {
      this.$swal
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, clean them up!",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.modalText = "Processing...";
              this.modalShow = true;

              await service.cleanUpAllDrawers();

              this.modalText = "Done!";
              setTimeout(() => (this.modalShow = false), 2000);
            } catch (error) {
              console.log(error);

              this.modalText = "An error occured!";
              this.modalShow = true;

              setTimeout(() => (this.modalShow = false), 2000);
            }
          }
        });
    },
  },
};
</script>
