<template>
  <div class="home">
    <template v-if="authenticated">
      <ConfigurationHome></ConfigurationHome>
    </template>
    <template v-else>
      <h3 class="question">
        It looks like you are not authorized for this resource...
      </h3>

      <div class="form-error" v-if="error.length > 0">
        <span>{{ error }}</span>
      </div>

      <form action="#" @submit="login">
        <div class="input-control">
          <input
            autocomplete="off"
            placeholder="----"
            type="text"
            name="password"
            v-model="password"
            ref="passwordInput"
            @input="onInputChange"
          />
          <button type="submit">Submit</button>
        </div>
      </form>
      <SimpleKeyboard @onChange="onChange" :input="password" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
.form-error {
  font-size: 42px;
  padding: 30px;
  font-family: "Anton";
  background-color: rgba(red, 0.5);
  color: #222;
  margin-bottom: 30px;
}

.input-control {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

input {
  width: 90%;
  padding: 20px;
  border: 3px solid #ddd;
  border-radius: 0;
  font-size: 36px;
  flex-basis: 90%;
  height: 100px;
}

input:focus {
  outline: none;
  border: 3px dashed blue;
  border-radius: 10px 0 0 10px;
}

button {
  background-color: #ddd;
  border: 3px solid #ddd;
  color: #222;
  width: 100%;
  height: 100px;
  flex-basis: 15%;
  cursor: pointer;
  font-size: 28px;
  font-weight: bold;
}

form {
  margin-bottom: 30px;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3.question {
    font-size: 42px;
    padding: 30px;
    font-family: "Anton";
    background-color: #ebebeb;
    color: #222;
    margin-bottom: 30px;
  }

  .action-boxes {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 20px;
    width: 100%;

    li {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-decoration: none;
        color: #fff;
        background-color: #ddd;
        margin: 10px 50px;
        width: 250px;

        .img img {
          display: block;
          width: 128px;
          padding: 10px;
          margin-top: 20px;
        }

        .title {
          display: block;
          background-color: #00838f;
          padding: 10px;
          color: #fff;
          width: 100%;
          text-align: center;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>

<script>
import SimpleKeyboard from "@/components/AlphaKeyboard";
import lockerConfig from "@/lockerConfig";
import ConfigurationHome from "@/components/Configuration/Home";

export default {
  name: "Configuration",
  components: {
    SimpleKeyboard,
    ConfigurationHome,
  },
  data() {
    return {
      password: "",
      polling: null,
      authenticated: false,
      error: "",
    };
  },
  mounted() {
    this.authenticated = false;
    this.polling = setInterval(() => {
      if (this.authenticated) return;
      this.$refs.passwordInput.focus();

      if (this.modalShow) {
        this.$refs.passwordInput.disabled = true;
      } else {
        this.$refs.passwordInput.disabled = false;
      }
    }, 0);
  },
  beforeUnmount() {
    clearInterval(this.polling);
  },
  methods: {
    login: function (e) {
      e.preventDefault();

      this.error = "";
      this.authenticated = this.password == lockerConfig.password;

      if (!this.authenticated) {
        this.error = "Wrong password.";
      }
    },
    onChange(input) {
      this.password = input;
    },
    onInputChange(input) {
      this.password = input.target.value;
    },
  },
};
</script>
